import { render, staticRenderFns } from "./EditRankings.vue?vue&type=template&id=5e91e80d&scoped=true"
import script from "./EditRankings.vue?vue&type=script&lang=js"
export * from "./EditRankings.vue?vue&type=script&lang=js"
import style0 from "./EditRankings.vue?vue&type=style&index=0&id=5e91e80d&prod&lang=css"
import style1 from "./EditRankings.vue?vue&type=style&index=1&id=5e91e80d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e91e80d",
  null
  
)

export default component.exports