<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="
          $router.push({
            name: 'PerformanceSettings',
            query: { activeTab: 'Ratings & Rankings' },
          })
        "
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />

      <div style="width: 300px; margin-top: 0.1rem">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          {{ title }}
        </h1>
      </div>
      <div class="w-7/12" style="margin-top: 1.2rem">
        <Breadcrumb
          :items="breadcrumbs"
          @onClick="handleBreadcrumbClick($event)"
        />
      </div>
    </div>

    <div style="height: 100%" class="mt-20" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div class="px-3" v-else>
      <ValidationObserver v-slot="{ handleSubmit }">
        <card class="p-5 mt-6">
          <p class="text-lg font-bold mb-7">
            Define Performance Ranking Criteria
          </p>

          <div
            class="flex w-1/4 my-5 cursor-pointer"
            @click="appraisalModal = true"
          >
            <icon :icon-name="addIcon" class="icon-add" />
            <span
              class="text-base font-semibold text-flame ml-2"
              style="margin-top: 0.1rem"
            >
              Add New Appraisal Flag
            </span>
          </div>

          <div
            class="flex w-1/4 mt-5 mb-7 cursor-pointer"
            @click="descriptionModal = true"
          >
            <icon :icon-name="addIcon" class="icon-add" />
            <span
              class="text-base font-semibold text-flame ml-2"
              style="margin-top: 0.1rem"
            >
              Add Performance Description
            </span>
          </div>

          <div class="w-full p-2 my-5 flex bg-ghostWhite">
            <div
              class="w-2/5 text-xs font-black mr-6 uppercase text-romanSilver"
            >
              Performance Band
            </div>
            <div
              class="w-2/5 text-xs mr-4 ml-1 font-black uppercase text-romanSilver"
            >
              Performance Description
            </div>
            <div
              class="w-2/5 text-xs mr-6 font-black uppercase text-romanSilver"
            >
              Appraisal Flag
            </div>
          </div>
          <div
            class="px-2 my-6 flex w-full"
            v-for="(rankings, index) in Ranking"
            :key="index"
          >
            <div class="w-2/5 mr-10 flex">
              <div class="text-base text-jet w-1/3 pt-3 leading-5">
                Score is
              </div>
              <div class="w-2/5 mr-2 label">
                <c-text v-if="index === 0" value="From" disabled label="" />
                <c-text v-else value="Above" disabled />
              </div>
              <div class="w-2/5 mr-2 label">
                <c-text
                  disabled
                  v-if="index === 0"
                  type="number"
                  v-model="rankings.from"
                />
                <c-text
                  v-else
                  type="number"
                  :rules="rankingRules"
                  :errors="rankings.lowScoreError"
                  v-model="rankings.from"
                  label="value"
                />
              </div>
              <div
                class="font-12 uppercase font-extrabold mr-2 text-jet"
                style="padding: 15px 0px"
              >
                To
              </div>
              <div class="w-2/5 label">
                <c-text
                  placeholder="--"
                  variant="w-full"
                  :errors="rankings.highScoreError"
                  type="number"
                  :rules="rankingRules"
                  v-model="rankings.to"
                  label="value"
                />
              </div>
            </div>

            <div class="w-2/5 mr-6 flex label">
              <c-select
                placeholder="--Description--"
                :options="descriptions"
                :rules="['required']"
                style="margin-top: -0.5%; width: 100%"
                label="Description"
                v-model="rankings.description"
              />
            </div>

            <div class="w-2/5 ml-4 mr-4 flex label">
              <c-select
                placeholder="--Appraisal Flag--"
                variant="w-full"
                :options="flags"
                :rules="['required']"
                style="margin-top: -0.5%; width: 100%"
                label="Appraisal flag"
                v-model="rankings.flagId"
              />
            </div>
            <div
              class="mt-2 cursor-pointer"
              style="width: 40px"
              v-if="index > 0 && index === Ranking.length - 1"
              @click="deleteRank(rankings.id, index)"
            >
              <icon :icon-name="trashIcon" size="xsm" />
            </div>
            <div class="mt-2" style="width: 40px" v-else />
          </div>
          <div class="flex my-4">
            <div
              class="flex w-1/4 p-1 cursor-pointer"
              @click="handleSubmit(createNewCriteria)"
            >
              <icon :icon-name="addIcon" class="icon-add" />
              <span class="text-base font-semibold text-flame ml-2">
                Add Performance Band
              </span>
            </div>
          </div>
        </card>
        <div class="flex footer_block">
          <Button
            class="bg-dynamicBackBtn text-white text-center"
            width="8rem"
            @click="handleSubmit(saveRankings)"
          >
            Save
          </Button>
          <Button
            background-color="none"
            class="ml-4 text-darkPurple text-center"
            width="8rem"
            @click="
              $router.push({
                name: 'PerformanceSettings',
                query: { activeTab: 'Ratings & Rankings' },
              })
            "
          >
            Cancel
          </Button>
        </div>
      </ValidationObserver>
    </div>

    <ValidationObserver v-slot="{ handleSubmit }">
      <RightSideBar
        v-if="appraisalModal"
        close-button
        @close="appraisalModal = false"
        button-class="bg-dynamicBackBtn text-white"
      >
        <template v-slot:title>
          <p class="text-darkPurple">Add Appraisal Flag</p>
        </template>
        <template v-slot:subtitle>
          <p class="font-semibold text-darkPurple" style="margin-bottom: 12px">
            Set the name for your appraisal flag.
          </p>
        </template>
        <div
          class="flex w-full my-4 label"
          v-for="(f, index) in appraisalFlags"
          :key="index"
        >
          <c-text
            v-model="f.name"
            placeholder="Enter Appraisal Flag"
            class="w-11/12"
            :rules="['required']"
            label="Appraisal Flag"
            :disabled="f.saved"
          />
          <div class="flex w-1/12 h-10">
            <div
              v-if="f.saved"
              class="cursor-pointer flex"
              @click="openEdit(f)"
            >
              <icon
                icon-name="icon-edit"
                size="xms"
                class="mt-1 mx-4 text-blueCrayola"
              />
            </div>
            <div
              class="flex cursor-pointer"
              v-else
              @click="
                f.edit
                  ? handleSubmit(editFlag(f))
                  : handleSubmit(submitAppraisal(f))
              "
            >
              <icon
                icon-name="save"
                class="mt-1 mx-4 text-blueCrayola"
                size="xms"
                style="width: 0.8rem"
              />
            </div>
            <div class="flex cursor-pointer" @click="deleteFlag(f, index)">
              <icon icon-name="icon-trash" class="mt-1" size="xs" />
            </div>
          </div>
        </div>
        <div
          class="text-flame font-semibold flex w-full my-4 p-2 text-base cursor-pointer"
          @click="createFlag"
        >
          <icon icon-name="icon-plus" class="mr-2" size="xsm" />
          Create Appraisal Flag
        </div>
      </RightSideBar>
    </ValidationObserver>

    <ValidationObserver v-slot="{ handleSubmit }">
      <RightSideBar
        v-if="descriptionModal"
        close-button
        @close="descriptionModal = false"
        button-class="bg-dynamicBackBtn text-white"
      >
        <template v-slot:title>
          <p class="text-darkPurple">Add Performance Description</p>
        </template>
        <template v-slot:subtitle>
          <p class="font-semibold text-darkPurple" style="margin-bottom: 12px">
            Set the name for your performance description.
          </p>
        </template>
        <div
          class="flex w-full my-4 label"
          v-for="(desc, index) in descriptionArray"
          :key="index"
        >
          <c-text
            v-model="desc.name"
            label="Performance Description"
            placeholder="Enter Performance Description"
            class="w-11/12"
            :rules="['required']"
            :disabled="desc.saved"
          />
          <div class="flex w-1/12 h-10">
            <div
              v-if="desc.saved"
              class="cursor-pointer flex"
              @click="openEditDesc(desc)"
            >
              <icon
                icon-name="icon-edit"
                size="xms"
                class="mt-1 mx-4 text-blueCrayola"
              />
            </div>
            <div
              class="flex cursor-pointer"
              v-else
              @click="
                desc.edit
                  ? handleSubmit(editDescription(desc))
                  : handleSubmit(submitDescription(desc))
              "
            >
              <icon
                icon-name="save"
                class="mt-1 mx-4 text-blueCrayola"
                size="xms"
                style="width: 0.8rem"
              />
            </div>
            <div
              class="flex cursor-pointer"
              @click="deleteDescription(desc, index)"
            >
              <icon icon-name="icon-trash" class="mt-1" size="xs" />
            </div>
          </div>
        </div>
        <div
          class="text-flame font-semibold flex w-full my-4 p-2 text-base cursor-pointer"
          @click="createDescription"
        >
          <icon icon-name="icon-plus" class="mr-2" size="xsm" />
          Create Performance Description
        </div>
      </RightSideBar>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import Button from "@/components/Button";
import RightSideBar from "@/components/RightSideBar";

export default {
  name: "EditRankings",
  components: {
    BackButton,
    ValidationObserver,
    Breadcrumb,
    Card,
    CText,
    RightSideBar,
    CSelect,
    Icon,
    Button,
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Settings",
          href: "Settings",
          id: "Settings",
        },
        {
          disabled: false,
          text: "Performance",
          href: "PerformanceSettings",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Setup Performance Rankings",
          id: "Edit rankings",
        },
      ],
      rankingRules: [
        "required",
        {
          name: "value",
          rule: (l) => this.validateValue(l),
        },
      ],
      appraisalModal: false,
      appraisalName: "",
      title: "Setup Performance Rankings",
      performanceDescription: "",
      descriptionModal: false,
      rankingCriteria: [],
      trashIcon: "icon-trash",
      addIcon: "icon-plus",
      Ratings: [],
      Ranking: [],
      lowest_rating: 0,
      highest_rating: 0,
      ratingsArr: [],
      lastScore: 0,
      disableBtn: false,
      flags: [],
      appraisalFlags: [],
      descriptions: [],
      descriptionArray: [],
      loading: true,
    };
  },

  methods: {
    watchRanking() {
      this.Ranking.reduce((previous, current) => {
        const previousValue = previous;
        const currentValue = current;

        if (currentValue.from !== "") {
          if (currentValue.from === previousValue.to) {
            currentValue.lowScoreError = [];
            this.disableBtn = false;
          } else {
            currentValue.lowScoreError.push(
              `Value must be equal to ${previousValue.to}`
            );
            this.disableBtn = true;
          }
        }

        if (currentValue.to !== "") {
          if (currentValue.to <= currentValue.from) {
            currentValue.highScoreError.push(
              `Value must be greater than ${currentValue.from}`
            );
            this.disableBtn = true;
          } else {
            currentValue.highScoreError = [];
            this.disableBtn = false;
          }
        }

        return current;
      });
    },

    validateValue(l) {
      if (l <= this.lowest_rating) {
        return `Value must be greater than minimum appraisal score ${this.lowest_rating}`;
      }
      if (l > this.highest_rating) {
        return `Value cannot be greater than maximum appraisal score ${this.highest_rating}`;
      }

      if (l > this.lowest_rating && l <= this.highest_rating) {
        this.watchRanking();
      }

      return true;
    },

    deleteRank(rankingId, index) {
      if (!rankingId) {
        this.Ranking.splice(index, 1);
      } else {
        this.$_deleteRanking(rankingId).then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.Ranking.splice(index, 1);
        });
      }
    },

    saveRankings() {
      if (!this.disableBtn) {
        const rankingArray = [];

        this.Ranking.filter((ranking) => {
          rankingArray.push({
            from: ranking.from,
            to: ranking.to,
            performance_description: ranking.description,
            orgId: ranking.orgId,
            flagId: ranking.flagId,
          });
          return {};
        });

        const payload = {
          orgId: this.$orgId,
          request: rankingArray,
        };
        this.$_createRanking(payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.$router.push({
              name: "PerformanceSettings",
              query: { activeTab: "Ratings & Rankings" },
            });
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      }
    },

    createNewCriteria() {
      let score;

      this.Ranking.map((rank) => {
        score = rank.to;
        return {};
      });

      this.lastScore = score;

      if (this.lastScore < this.highest_rating) {
        if (!this.disableBtn) {
          this.lastScore = Number(this.lastScore);
          this.Ranking.push({
            lowScoreError: [],
            highScoreError: [],
            id: "",
            from: this.lastScore.toString(),
            to: "",
            description: "",
            orgId: this.$orgId,
            flagId: "",
          });
        }
      } else {
        this.$toasted.error(
          `You have reached your maximum appraisal rating of ${this.highest_rating}`,
          { duration: 5000 }
        );
      }
    },

    async getPerfomanceRating() {
      try {
        const getPerfomanceRating = await this.$_getPerfomanceRating();
        const getRanking = await this.$_getRanking();

        const rankingsArry = getRanking.data.rankings;

        this.Ratings = getPerfomanceRating.data.PerformanceRatings;

        const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);

        this.Ratings = sortedRatings;

        this.lowest_rating = Math.min.apply(
          this.Ratings,
          this.Ratings.map((item) => item.rating)
        );
        this.highest_rating = Math.max.apply(
          this.Ratings,
          this.Ratings.map((item) => item.rating)
        );

        if (rankingsArry.length === 0) {
          this.Ranking.push({
            lowScoreError: [],
            highScoreError: [],
            id: "",
            from: this.lowest_rating !== null ? this.lowest_rating : "---",
            to: "",
            description: "",
            orgId: this.$orgId,
            flagId: "",
          });
        } else {
          this.title = "Edit Performance Rankings";
          this.breadcrumbs[2].text = "Edit Performance Rankings";
          rankingsArry.filter((rankingItem) => {
            this.Ranking.push({
              lowScoreError: [],
              highScoreError: [],
              id: rankingItem.id,
              from: rankingItem.from,
              to: rankingItem.to,
              description: rankingItem.performance_description,
              orgId: this.$orgId,
              flagId: rankingItem.flagId,
            });
            return {};
          });
        }

        this.loading = false;
      } catch (err) {
        throw new Error(err);
      }
    },

    openEdit(flags) {
      const indexedFlags = flags;
      indexedFlags.saved = false;
      indexedFlags.edit = true;
    },

    createFlag() {
      this.appraisalFlags.push({
        id: null,
        name: "",
        saved: false,
        edit: false,
      });
    },

    submitAppraisal(flag) {
      const indexedFlag = flag;

      const payload = {
        orgId: this.$orgId,
        name: flag.name,
      };

      indexedFlag.saved = true;

      this.$_createAppraisalFlag(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.appraisalName = "";
          this.getAppraisalFlag();
        })
        .catch((err) => {
          this.$toasted.error("Error in creating Appraisal Flag", {
            duration: 5000,
          });
          throw new Error(err);
        });
    },

    editFlag(flag) {
      let payload = {};
      const indexedFlag = flag;

      payload = {
        id: flag.id,
        orgId: this.$orgId,
        name: flag.name,
      };

      indexedFlag.saved = true;

      this.$_updateAppraisalFlag(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getAppraisalFlag();
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
          indexedFlag.saved = false;
        });
    },

    deleteFlag(flag, index) {
      if (flag.id) {
        this.$_deleteAppraisalFlag(flag.id).then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getAppraisalFlag();
        });
      }
      this.appraisalFlags.splice(index, 1);
    },

    async getAppraisalFlag() {
      this.flags = [];
      this.appraisalFlags = [];
      try {
        const getAppraisalFlag = await this.$_getAppraisalFlag();

        getAppraisalFlag.data.flags.filter((flag) => {
          this.flags.push({ id: flag.id, name: flag.name });
          this.appraisalFlags.push({
            id: flag.id,
            name: flag.name,
            saved: true,
            edit: false,
          });
          return {};
        });
      } catch (err) {
        throw new Error(err);
      }
    },

    openEditDesc(desc) {
      const indexedDesc = desc;
      indexedDesc.saved = false;
      indexedDesc.edit = true;
    },

    editDescription(description) {
      let payload = {};
      const indexedDesc = description;

      payload = {
        id: description.id,
        orgId: this.$orgId,
        description: description.name,
      };

      indexedDesc.saved = true;

      this.$_updatePerformanceDescription(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getDescription();
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
          indexedDesc.saved = false;
        });
    },

    deleteDescription(desc, index) {
      if (desc.id) {
        this.$_deletePerformanceDescription(desc.id).then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getDescription();
        });
      }
      this.descriptionArray.splice(index, 1);
    },

    submitDescription(desc) {
      const indexedDesc = desc;

      const payload = {
        orgId: this.$orgId,
        description: desc.name,
      };

      indexedDesc.saved = true;

      this.$_createPerfomanceDescription(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getDescription();
        })
        .catch((err) => {
          this.$toasted.error("Error in creating Performance Description", {
            duration: 5000,
          });
          throw new Error(err);
        });
    },

    createDescription() {
      this.descriptionArray.push({
        id: null,
        name: "",
        saved: false,
        edit: false,
      });
    },

    async getDescription() {
      this.descriptions = [];
      this.descriptionArray = [];

      try {
        const getDescription = await this.$_getPerformanceDescription();

        getDescription.data.performanceDescription.filter((item) => {
          this.descriptions.push({ id: item.id, name: item.description });
          this.descriptionArray.push({
            id: item.id,
            name: item.description,
            saved: true,
            edit: false,
          });
          return {};
        });
      } catch (err) {
        throw new Error(err);
      }
    },

    handleBreadcrumbClick(event) {
      this.$router.push({
        name: event,
      });
    },
  },

  mounted() {
    this.getPerfomanceRating();
    this.getAppraisalFlag();
    this.getDescription();
  },
};
</script>

<style>
.label label {
  display: none;
}
</style>

<style scoped>
.icon {
  width: 18px;
  height: 18px;
  margin: 12px 0px;
  padding: 3px;
}
.icon-add {
  width: 26px;
  height: 26px;
  margin: 0px 8px;
  padding: 3px;
}
.add__text {
  font-size: 16px;
  letter-spacing: 0.015em;
  margin: 0px 8px;
}
.footer_block {
  width: 800px;
  height: 44px;
  margin-top: 13px;
}
.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}
</style>
